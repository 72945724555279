@import '../../../styles/color.scss';

$mq-potrait: 600px;
$mq-landscape: 1023px;
$mq-height : 900px;

.EnterCodeDisabled {
    background: $gray-1 !important;
    box-shadow: 0px 3px 0px #F0F0F0 !important;
}

.imagebackground {
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    z-index: -5;
}

.enterCodeContainer {
    @media screen and (min-width: $mq-potrait) {
        max-width: 350px;
    }
}

.inputField {
    border: 1px solid $gray-5;
    border-radius: 4px;
    outline: none;
}

.inputActiveBorder{
    border-color: $primary;
}

.inputField::placeholder {
    color: $gray-3;
}