.toasts-container {
    // position: fixed;
    pointer-events: none;
    // display: flex;
    .toast-msg {
      * {
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
      }
    }
  }
  
  @mixin top-center-container {
    .toast-msg {
      transform: translateY(-100%);
      animation: top-center 0.3s ease-in-out 0s 1 forwards;
    }
  }
  @mixin bottom-center-container {
    .toast-msg {
      transform: translateY(100%);
      animation: bottom-center 0.3s ease-in-out 0s 1 forwards;
    }
  }
  
  @mixin top-left-container {
    .toast-msg {
      transform: translateX(-100vw);
      animation: top-left 0.3s ease-in-out 0s 1 forwards;
    }
  }
  @mixin top-right-container {
    .toast-msg {
      transform: translateX(100vw);
      animation: top-right 0.3s ease-in-out 0s 1 forwards;
    }
  }
  @mixin bottom-left-container {
    .toast-msg {
      transform: translateX(100vw);
      animation: top-left 0.3s ease-in-out 0s 1 forwards;
    }
  }
  @mixin bottom-right-container {
    .toast-msg {
      transform: translateX(100vw);
      animation: top-right 0.3s ease-in-out 0s 1 forwards;
    }
  }
  
  .pos-top_right {
    @include top-right-container();
  }
  .pos-top_center {
    @include top-center-container();
  }
  .pos-top_left {
    @include top-left-container();
  }
  .pos-bottom_left {
    @include bottom-left-container();
  }
  .pos-bottom_center {
    @include bottom-center-container();
  }
  .pos-bottom_right {
    @include bottom-right-container();
  }
  .toast-msg {
    pointer-events: auto;
    min-width: 100px;
    * {
      font-weight: 300;
    }
    h1 {
      font-size: 14px;
      margin-left: 10px;
    }
  }
  @keyframes top-center {
    0% {
      transform: translateY(-100%);
      opacity: 0;
    }
    60% {
      transform: translateY(-40%);
      opacity: 0;
    }
    100% {
      transform: translateY(0%);
      opacity: 1;
    }
  }
  @keyframes bottom-center {
    0% {
      transform: translateY(100%);
      opacity: 0;
    }
    60% {
      transform: translateY(60%);
      opacity: 0;
    }
    100% {
      transform: translateY(0%);
      opacity: 1;
    }
  }
  @keyframes top-right {
    0% {
      transform: translateX(100vw);
    }
    100% {
      transform: translateX(0vw);
    }
  }
  @keyframes top-left {
    0% {
      transform: translateX(-100vw);
    }
    100% {
      transform: translateX(0vw);
    }
  }