.indicator {
    color: rgba(255, 255, 255, 0.5);
	background: linear-gradient(99deg, rgba(255,255,255,0) 42%, currentColor 53%, rgba(255,255,255,0) 65%);
	animation: move 1s ease-in-out 0s infinite forwards;
}
@keyframes move {
	from {
		transform: translateX(-100vw);
	}
	to {
		transform: translateX(100vw);
	}
}