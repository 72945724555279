@mixin for-medium{
    @media only screen and
    (max-width: 1024px) and (min-width: 600px) and (orientation: portrait),
    (max-width: 1366px) and (min-width: 960px) and (orientation: landscape){
        @content;
    }
}

@mixin for-small {
    @media only screen and (max-width: 599px) and (orientation: portrait), (max-width: 412px), (max-width: 959px) and (orientation: landscape) and (min-width: 413px){
        @content;
    }
}
  

.coach-mark {
    &.with-tip {
        &::before {
            content: '';
            position: absolute;
            background-color: #0066FA;
            width: 28px;
            height: 28px;
            transform: rotate(-45deg);
            @include for-medium {
                width: 20px;
                height: 20px;
            }
            @include for-small {
                width: 20px;
                height: 20px;
            }
        }
    }
    &--top {
        transform: translateX(-50%);
        margin-bottom: 20px;
        @include for-medium {
            margin-bottom: 13px;
        }
        @include for-small {
            margin-bottom: 13px;
        }
        &.with-tip {
            &::before {
                border-bottom-left-radius: 3px;
                top: calc(100% - 20px);
                left: calc(50% - 14px);
                @include for-medium {
                    top: calc(100% - 14px);
                    left: calc(50% - 10px);
                }
                @include for-small {
                    top: calc(100% - 14px);
                    left: calc(50% - 10px);
                }
            }
        }
    }
    &--bottom {
        transform: translateX(-50%);
        margin-top: 20px;
        @include for-medium {
            margin-top: 13px;
        }
        @include for-small {
            margin-top: 13px;
        }
        &.with-tip {
            &::before {
                border-top-right-radius: 3px;
                top: -8px;
                left: calc(50% - 14px);
                @include for-medium {
                    top: -6px;
                    left: calc(50% - 10px);
                }
                @include for-small {
                    top: -6px;
                    left: calc(50% - 10px);
                }
            }
        }
    }
    &--left {
        transform: translateY(-50%);
        margin-right: 20px;
        @include for-medium {
            margin-right: 13px;
        }
        @include for-small {
            margin-right: 13px;
        }
        &.with-tip {
            &::before {
                border-bottom-right-radius: 3px;
                top: calc(50% - 14px);
                left: calc(100% - 20px);
                @include for-medium {
                    top: calc(50% - 10px);
                    left: calc(100% - 14px);
                }
                @include for-small {
                    top: calc(50% - 10px);
                    left: calc(100% - 14px);
                }
            }
        }
    }
    &--right {
        transform: translateY(-50%);
        margin-left: 20px;
        @include for-medium {
            margin-left: 13px;
        }
        @include for-small {
            margin-left: 13px;
        }
        &.with-tip {
            &::before {
                border-top-left-radius: 3px;
                top: calc(50% - 14px);
                left: -8px;
                @include for-medium {
                    top: calc(50% - 10px);
                    left: -6px;
                }
                @include for-small {
                    top: calc(50% - 10px);
                    left: -6px;
                }
            }
        }
    }
    .coach-mark-description {
        line-height: 20px;
        @include for-medium {
            line-height: 16px;
        }
        @include for-small {
            line-height: 16px;
        }
    }
}