.popup-overlay {
    background-color: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .popup-holder {
        border-radius: 4px;
        background-color: #fff;
        width: 300px;
        @media (min-width: 768px) {
            width: 420px;
        }
        position: relative;
        .popup-close-holder{
            text-align: right;
            display: block;
            .popup-close-icon {
                position: absolute;
                display: inline-block;
                width: 40px;
                height: 40px;
                top: 0px;
                right: 0px;
                cursor: pointer;
            }
        }
        .popup-body{ 
            padding: 12px;
            text-align: center;
        }
    }
}