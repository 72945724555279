@import "../../../styles/color.scss";

.SecondaryBtn {
  box-shadow: 0px 3px 0px #f0f0f0;
  border-style: reset !important;
}

.PrimaryBtn {
  box-shadow: 0px 2px 0px #f35427;
}

.RippleEffectBtn {
  background-position: center;
  transition: background 0.8s;
  position: relative;
  overflow: hidden;
  background-position: center;
}

.RippleEffectBtn:hover {
  background: rgba(255, 255, 255, 0.15)
    radial-gradient(circle, rgba(255, 255, 255, 0.15) 1%, transparent 1%)
    center/15000%;
}

.RippleEffectBtn:active {
  background-color: rgba(255, 255, 255, 0.5);
  background-size: 100%;
  transition: background 0s;
}
