.animate-up {
    animation: animate-up 2s ease-in forwards;
    -webkit-animation: animate-up 2s ease-in forwards;
    -moz-animation: animate-up 2s ease-in forwards;
    -ms-animation: animate-up 2s ease-in forwards;
    -o-animation: animate-up 2s ease-in forwards;
}

@-webkit-keyframes animate-up {
    0% {
        opacity: 1;
        transform: translateY(40px) translateX(0px);
    }
    50% {
        opacity: 0.9;
    }
    100% {
        transform: translateY(-100%) translateX(-10px);
        opacity: 0;
    }
}

@-moz-keyframes animate-up {
    0% {
        opacity: 1;
        transform: translateY(40px) translateX(0px);
    }
    50% {
        opacity: 0.9;
    }
    100% {
        transform: translateY(-100%) translateX(-10px);
        opacity: 0;
    }
}

@-o-keyframes animate-up {
    0% {
        opacity: 1;
        transform: translateY(40px) translateX(0px);
    }
    50% {
        opacity: 0.9;
    }
    100% {
        transform: translateY(-100%) translateX(-10px);
        opacity: 0;
    }
}